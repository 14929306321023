import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AboutComponent} from './about/about.component';
import {HomeComponent} from './home/home.component';
import {ContactComponent} from './contact/contact.component';
import { ConsultingComponent } from './consulting/consulting.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
   { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent }, 
  { path: 'consulting', component: ConsultingComponent }
  
 
];

@NgModule({
  imports: [ RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
