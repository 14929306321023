import { Directive, ElementRef, Renderer2 } from '@angular/core';

declare var jQuery: any;

@Directive({
  selector: '[appSliderRender]'
})
export class SliderRenderDirective {

  constructor(elem: ElementRef, renderer: Renderer2) { 
        console.log("Rendering ");
        
     jQuery('.rev_slider').isotope({
        // options
            itemSelector: '.rev_slider',
                layoutMode: 'masonry'
                });
     console.log("Rendering Complete ");
                
       
}
}
