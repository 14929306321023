import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { AppRoutingModule } from './/app-routing.module';
import { HomeComponent } from './home/home.component';

import { ContactComponent } from './contact/contact.component';
import { SliderRenderDirective } from './slider-render.directive';
import { SliderComponent } from './slider/slider.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';

import {APP_BASE_HREF} from '@angular/common';

import { IntroComponent } from './intro/intro.component';
import { ConsultingComponent } from './consulting/consulting.component';

import { GaDirective } from './ga.directive';


//import { revslider } from 'angular-revolution';


@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HomeComponent, 
    ContactComponent,
    SliderRenderDirective,
    SliderComponent,
    NavbarComponent,
    FooterComponent,
    IntroComponent,
    ConsultingComponent,
    GaDirective

   
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule
 
  ],
  providers: [
        {provide: APP_BASE_HREF, useValue : '/' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
