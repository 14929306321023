import { Directive,HostListener,Input } from '@angular/core';



@Directive({
  selector: '[appGa]'
})
export class GaDirective {

  constructor() { }
  
  @Input('eventTracker') option:any;

  @HostListener('click', ['$event']) onClick($event){

    (<any>window).ga('send', 'event', this.option.category, this.option.action, {
      hitCallback: function() {

        console.log('Tracking is successful');

      }

    });

  }
 

}
