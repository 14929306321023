import { Component, OnInit, AfterViewInit, ElementRef,Inject, PLATFORM_ID ,HostBinding} from '@angular/core';

import { NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import * as $ from 'jquery/dist/jquery.min.js';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { isPlatformBrowser } from '@angular/common';
import { SEMICOLON } from '../theme/js/functions.js';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../style.css' ]
})
export class AppComponent implements OnInit {


wrapper: any;

constructor(private elementRef:ElementRef, private router: Router) {

     
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
        console.log("Tracked one event ");
      }
    });
    };
    
  title = 'app';
  
  
   ngOnInit() {
        
      console.log("In the App componenet");
        
    }


  
  
// For Google Analytics
  sendEvent = (type: string, cat: string) => {
    (<any>window).ga('send', 'event', {
      eventCategory: cat,
      eventLabel: type,
      eventAction: type,
      eventValue: 10
    });
  }
  sendmail(){
     console.log("In the app componenet for sending mail");
  }
}


