import { Component, OnInit, AfterViewInit,OnDestroy,Inject, PLATFORM_ID  } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
//import * as $ from 'jquery/dist/jquery.min.js';

import { isPlatformBrowser } from '@angular/common';


declare var jQuery: any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements AfterViewInit, OnDestroy {

slider: any;
  constructor(private elementRef:ElementRef, private router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
     //this.router.events.subscribe(event => {
     // if (event instanceof NavigationEnd) {
     //  (<any>window).ga('set', 'page', event.urlAfterRedirects);
     //  (<any>window).ga('send', 'pageview');
    //  }
   // });
    };


  ngOnDestroy(): void {
    // RS's kill switch
    this.slider.revkill();
  }


  ngOnInit() {
  console.log ("App Slider initiated");
   // jQuery.timeout(function () {
   // var iso = new Isotope(element[0], {
    //    itemSelector: '.div',
     //   layoutMode: 'fullscreen'
     //   });
  //   },0);
     //jQuery('.rev_slider').isotope({
        // options
        //    itemSelector: '.rev_slider',
             //   layoutMode: 'masonry'
          //      });
    
    }

ngAfterViewInit(): void {


      // jQuery('.rev_slider').isotope({
        // options
       //     itemSelector: '.rev_slider_579_1',
        //        layoutMode: 'masonry'
        //        });
                
  //  if (isPlatformBrowser(this.platformId)) { // due to server side rendering check if browser
    //  setTimeout(() => {
   //     this.slider = (<any>window).$(this.elementRef.nativeElement)
     //     .find('.rev_slider')
      //    .revolution(this.getSliderOptions());
   //   }, 10);
  //  }
  }

  getSliderOptions(): any {
    return {
      sliderType: "standard",
					jsFileLocation: "theme/include/rs-plugin/js/",
					sliderLayout: "fullscreen",
					dottedOverlay: "none",
					delay: 9000,
					responsiveLevels: [1140, 1024, 778, 480],
					visibilityLevels: [1140, 1024, 778, 480],
					gridwidth: [1140, 1024, 778, 480],
					gridheight: [728, 768, 960, 720],
					lazyType: "none",
					shadow: 0,
					spinner: "off",
					stopLoop: "on",
					stopAfterLoops: 0,
					stopAtSlide: 1,
					shuffle: "off",
					autoHeight: "off",
					fullScreenAutoWidth: "off",
					fullScreenAlignForce: "off",
					fullScreenOffsetContainer: "",
					fullScreenOffset: "",
					disableProgressBar: "on",
					hideThumbsOnMobile: "off",
					hideSliderAtLimit: 0,
					hideCaptionAtLimit: 0,
					hideAllCaptionAtLilmit: 0,
					debugMode: false,
					fallbacks: {
						simplifyAll:"off",
						disableFocusListener:false,
					},
					parallax: {
						type:"mouse",
						origo:"slidercenter",
						speed:300,
						levels:[2,4,6,8,10,12,14,16,18,20,22,24,49,50,51,55],
					},
					navigation: {
						keyboardNavigation:"off",
						keyboard_direction: "horizontal",
						mouseScrollNavigation:"off",
						onHoverStop:"off",
						touch:{
							touchenabled:"on",
							swipe_threshold: 75,
							swipe_min_touches: 1,
							swipe_direction: "horizontal",
							drag_block_vertical: false
						},
						arrows: {
							style: "hermes",
							enable: true,
							hide_onmobile: false,
							hide_onleave: false,
							tmp: '<div class="tp-arr-allwrapper">	<div class="tp-arr-imgholder"></div>	<div class="tp-arr-titleholder">{{title}}</div>	</div>',
							left: {
								h_align: "left",
								v_align: "center",
								h_offset: 10,
								v_offset: 0
							},
							right: {
								h_align: "right",
								v_align: "center",
								h_offset: 10,
								v_offset: 0
							}
						}
					}
            };
    }


}
