import { Component, OnInit ,OnDestroy} from '@angular/core';
import {environment} from "../../environments/environment";
import { FormsModule }   from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';


import * as AWS from "aws-sdk";
import { ViewChild, ElementRef } from '@angular/core';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, OnDestroy {

  public cdata = new contactData();
  public message: string; 
  slider: any;
  constructor(private elementRef:ElementRef, private router: Router) {
     this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
    };

  ngOnInit() {
    AWS.config.region = environment.region; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: environment.identityPoolId,
    });
  }
  onSubmit() { 

   
  }
  ngOnDestroy(): void {
    // RS's kill switch
    this.slider.revkill();
  }
  
  @ViewChild('submitButton') submitButton:ElementRef;
  sendmail(){
        
        this.submitButton.nativeElement.disabled = true;
        var sns = new AWS.SNS({apiVersion: '2010-03-31'});
        var params = {
                //Message: 'Hello topic', /* required */
                Message: JSON.stringify(this.cdata),
                Subject: 'Noctil - contact form',
                TopicArn: 'arn:aws:sns:us-east-1:436177021960:com-noctil-contact-form'
            };
            sns.publish(params, function(err, data) {
                if (err) 
                    this.message = 'Unable to process your request now.'; // an error occurred
                else              
                    this.message = 'Thank you!';  // successful response
            });
            this.message = 'Thank you!'; 
  }
  
}
export class contactData {

  constructor() {  
    }
    public subject: number;
    public phone: string;
    public name: string;
    public email: string;
    public message: string;
}