import { Component, OnInit, AfterViewInit,OnDestroy,Inject, PLATFORM_ID  } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { SliderComponent } from '../slider/slider.component';

import { isPlatformBrowser } from '@angular/common';


declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit, OnDestroy {

slider: any;
  constructor(private elementRef:ElementRef, private router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
     //this.router.events.subscribe(event => {
     // if (event instanceof NavigationEnd) {
     //  (<any>window).ga('set', 'page', event.urlAfterRedirects);
     //  (<any>window).ga('send', 'pageview');
    //  }
   // });
    };


  ngOnDestroy(): void {
    // RS's kill switch
    //this.slider.revkill();
  }


  ngOnInit() {
    
    }

ngAfterViewInit(): void {
   // $(this.elementRef.nativeElement).show();
    //this.elementRef.nativeElement.scrollIntoView();
    console.log("In the Home componenet");

  }

}
